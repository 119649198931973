import React from 'react';
import '../css/AboutUs.scss';

const AboutUs = () => {
  return (
    <div className="about-us">
      <section className="section about-us-intro">
        <div className="content">
          <h1>About Us</h1>
          <p>We are passionate about creating unique and high-quality products.</p>
        </div>
      </section>

      <section className="section about-us-history">
        <div className="content">
          <h2>Our History</h2>
          <p>From humble beginnings to where we are now, we’ve come a long way.</p>
        </div>
      </section>

      <section className="section about-us-team">
        <div className="content">
          <h2>Meet Our Team</h2>
          <p>We are a group of dedicated professionals with a shared passion for excellence.</p>
        </div>
      </section>

      <section className="section about-us-values">
        <div className="content">
          <h2>Our Values</h2>
          <p>We believe in innovation, sustainability, and customer satisfaction.</p>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
