import React, { useState, useEffect, useRef } from 'react';
import './dashboard.css';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_ENDPOINTS } from './connectapi';

import RegisterForm from './Forms/Registerform';
import CreateProductForm from './Forms/Addproductform';
import ProductManagement from './Forms/Productmange';
import ProductVariationForm from './Forms/Addvariationsform';

const Dashboard = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);  // Loading state for session check
  const [openMenus, setOpenMenus] = useState({});
  const [currentView, setCurrentView] = useState(''); // Track the current view
  const [currentProductId, setCurrentProductId] = useState(null);
  const [currentCollectionId, setCurrentCollectionId] = useState(null);
  const [currentVariationId, setCurrentVariationId] = useState(null);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await axios.get(API_ENDPOINTS.checkSession, {
          withCredentials: true, // Ensure cookies are sent
        });

        if (response.status === 200 && response.data.message === "Session active") {
          setIsLoading(false); // User is logged in
        } else {
          navigate('/LoginPage'); // Not logged in, redirect to login
        }
      } catch (error) {
        navigate('/LoginPage'); // On error, redirect to login
      }
    };

    checkSession();
  }, [navigate]);



  if (isLoading) {
    // Display a loading indicator while checking the session
    return <div>Loading...</div>;
  }

  const toggleMenu = (menu) => {
    setOpenMenus((prevOpenMenus) => ({
      ...prevOpenMenus,
      [menu]: !prevOpenMenus[menu],
    }));
  };

  const handleSubMenuClick = (view, event) => {
    event.stopPropagation();
    setCurrentView(view);
  };

  const handleProductCreated = (productId) => {
    setCurrentProductId(productId);  // Correctly set the productId
    setCurrentView('createProductVariation');
    console.log('Created Product ID:', productId); // Debugging

  };

  const handleVariationCreated = () => {
    setCurrentView('productmanagement');
  }


  return (
    <div className="dashboard">
      <NavbarDashboard />
      <div className="main-content">
        <Menu
          openMenus={openMenus}
          toggleMenu={toggleMenu}
          handleSubMenuClick={handleSubMenuClick}
        />
        <DashboardSpace
          currentView={currentView}
          currentProductId={currentProductId}
          onProductCreated={handleProductCreated}
          onSubmissionSuccess={handleVariationCreated}
          setCurrentView={setCurrentView}
          currentCollectionId={currentCollectionId}
          setCurrentCollectionId={setCurrentCollectionId}
          setCurrentProductId={setCurrentProductId}
          currentVariationId={currentVariationId}
          setCurrentVariationId={setCurrentVariationId}
        />
      </div>
    </div>
  );
};

const Menu = ({ openMenus, toggleMenu, handleSubMenuClick }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const response = await axios.post(
        API_ENDPOINTS.loginUser, // Use the same endpoint as login
        { action: 'logout' },
        { withCredentials: true } // Ensure cookies are included
      );

      if (response.status === 200 && response.data.message === "Logged out") {
        navigate('/LoginPage'); // Redirect to login page
      } else {
        console.error('Failed to log out');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <div className="menu">
      <ul>
        <li>Dashboard</li>

        <li onClick={() => toggleMenu('product')} className={openMenus.product ? 'open' : ''}>
          จัดการสินค้า
          <ul className={`submenu ${openMenus.product ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}>
            <li onClick={(e) => handleSubMenuClick('addproductform', e)}>เพิ่มสิ้นค้าใหม่</li>
            <li onClick={(e) => handleSubMenuClick('productmanagement', e)}>จัดการรายการสินค้า</li>
            <li onClick={(e) => handleSubMenuClick('collectionmanage', e)}>คอลเลคชั่น</li>
            <li onClick={(e) => handleSubMenuClick('createCollection', e)}>สร้างคอลเลคชั่นใหม่</li>
          </ul>
        </li>
        <li onClick={() => toggleMenu('user')} className={openMenus.user ? 'open' : ''}>
          ผู้ใช้
          <ul className={`submenu ${openMenus.user ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}>
            <li onClick={(e) => handleSubMenuClick('createUser', e)}>เพิ่มผู้ใช้</li>
            <li onClick={(e) => handleSubMenuClick('userList', e)}>สมาชิกทั้งหมด</li>
          </ul>
        </li>
        <li onClick={() => toggleMenu('settings')} className={openMenus.settings ? 'open' : ''}>
          ตั้งค่า
          <ul className={`submenu ${openMenus.settings ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}>
            <li>General</li>
            <li>Account</li>
          </ul>
        </li>
        <li onClick={(e) => handleSubMenuClick('createProductVariation', e)}>ติดต่อ Support</li>
        <li onClick={handleLogout}>ออกจากระบบ</li>
      </ul>
    </div>
  );
};

const DashboardSpace = ({
  currentView,
  currentProductId,
  onProductCreated,
  onSubmissionSuccess,
  setCurrentView,
  setCurrentProductId,
  currentVariationId,
  setCurrentVariationId,
  currentCollectionId,
  setCurrentCollectionId
}) => {
  const handleProductUpdated = () => {
    setCurrentView('productManagement');
    // Optionally, refresh the product list
  };

  const handleVariationUpdated = () => {
    setCurrentView('productManagement');
    // Optionally, refresh the product list
  };
  return (
    <div className="dashboardspace">
      {currentView === 'createUser' && <RegisterForm />}
      {currentView === 'addproductform' && (
        <CreateProductForm onProductCreated={onProductCreated} />
      )}
      {currentView === 'editProduct' && (
        <CreateProductForm
          productId={currentProductId}
          isEdit={true}
          onProductUpdated={handleProductUpdated}
        />
      )}
      {currentView === 'productmanagement' && (
        <ProductManagement
          setCurrentView={setCurrentView}
          setCurrentProductId={setCurrentProductId}
        />
      )}
      {currentView === 'createProductVariation' && (
        <ProductVariationForm
          productId={currentProductId}
          onSubmissionSuccess={onSubmissionSuccess}
        />
      )}
      {currentView === 'editVariation' && (
        <ProductVariationForm
          variationId={currentVariationId}
          isEdit={true}
          onVariationUpdated={handleVariationUpdated}
        />
      )}
    </div>
  );
};


const NavbarDashboard = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(null); // State to store user info
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const response = await axios.post(
        API_ENDPOINTS.loginUser,
        { action: 'logout' },
        { withCredentials: true }
      );

      if (response.status === 200 && response.data.message === "Logged out") {
        navigate('/LoginPage');
      } else {
        console.error('Failed to log out');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    // Fetch user info when component mounts
    const fetchUserInfo = async () => {
      try {
        const response = await axios.post(
          API_ENDPOINTS.loginUser,
          { action: 'get_user_info' },
          { withCredentials: true }
        );
        if (response.status === 200 && response.data.message === "User info retrieved") {
          setUserInfo(response.data.data);
        } else {
          console.error('Failed to retrieve user info');
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
        navigate('/LoginPage'); // Redirect to login if unauthorized
      }
    };

    fetchUserInfo();

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navigate]);

  return (
    <div>
      <div>
        <nav className="navbar_dashboard">
          <div className="logo">DOUBLE PLALOMA</div>
          <div className="search-box">
            <input type="text" placeholder="Search..." />
          </div>
          <div className="user" onClick={toggleMenu} ref={menuRef}>
            <div className="user-name">
              ยินดีตอนรับ {userInfo ? userInfo.user_name : ''}
            </div>
            <div className={`user-menu ${menuOpen ? 'open' : ''}`}>
              {userInfo ? (
                <>
                  <p>Full Name: {userInfo.user_name}</p>
                  <p>Email: {userInfo.user_email}</p>
                </>
              ) : (
                <p>Loading user info...</p>
              )}
              <p>Contact Support</p>
              <hr />
              <button onClick={handleLogout}>Log Out</button>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Dashboard;
