import React, { useState, useEffect } from 'react';
import './Dropdown.scss';
import { FaCaretDown, FaFilter } from "react-icons/fa";
import axios from 'axios';
import { API_ENDPOINTS } from '../backended/connectapi';

const Dropdown = ({ onSortChange , onFilterChange  }) => {
  const options = [
    'สินค้าใหม่',           // Sort by newest products
    'ราคา น้อย ⇢ มาก',      // Sort by price (low to high)
    'ราคา มาก ⇢ น้อย',      // Sort by price (high to low)
  ];

  const [selectedIndex, setSelectedIndex] = useState(0); // Default to "สินค้าใหม่"
  const [isFilterOpen, setIsFilterOpen] = useState(false); // Filter open state
  const [isCategoryOpen, setIsCategoryOpen] = useState(false); // Toggle for หมวดหมู่ (Categories)
  const [isStyleOpen, setIsStyleOpen] = useState(false); // Toggle for สไตล์ (Styles)
  const [isColorOpen, setIsColorOpen] = useState(false); // Toggle for สี (Colors)

  const [categories, setCategories] = useState([]); // Dynamic categories
  const [styles, setStyles] = useState([]); // Dynamic styles
  const [filteredStyles, setFilteredStyles] = useState([]); // Filtered styles based on selected category
  const [colors, setColors] = useState([]); // Dynamic colors
  const [selectedCategories, setSelectedCategories] = useState([]); // Track selected categories
  const [selectedStyles, setSelectedStyles] = useState([]); // Track selected styles
  const [selectedColors, setSelectedColors] = useState([]); // Track selected colors

  useEffect(() => {
    const savedStyles = localStorage.getItem('selectedStyles');
    if (savedStyles) {
      try {
        const parsedStyles = JSON.parse(savedStyles);
        setSelectedStyles(parsedStyles);
      } catch (error) {
        console.error('Error parsing selected styles from local storage:', error);
      }
    } else {
      console.log('No styles found in local storage');
    }
  }, []);

  useEffect(() => {
    const savedCategories = JSON.parse(localStorage.getItem('selectedCategories')) || [];
    const savedStyles = JSON.parse(localStorage.getItem('selectedStyles')) || [];
    const savedColors = JSON.parse(localStorage.getItem('selectedColors')) || [];
    const savedSortOption = JSON.parse(localStorage.getItem('selectedSortOption')) || 0;
    
    setSelectedCategories(savedCategories);
    setSelectedStyles(savedStyles);
    setSelectedColors(savedColors);
    setSelectedIndex(savedSortOption);
  }, []);

    // Save filters in localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('selectedCategories', JSON.stringify(selectedCategories));
  }, [selectedCategories]);

  useEffect(() => {
    localStorage.setItem('selectedStyles', JSON.stringify(selectedStyles));
  }, [selectedStyles]);

  useEffect(() => {
    localStorage.setItem('selectedColors', JSON.stringify(selectedColors));
  }, [selectedColors]);

  useEffect(() => {
    localStorage.setItem('selectedSortOption', JSON.stringify(selectedIndex));
  }, [selectedIndex]);

    // Update filters when they change
    useEffect(() => {
      onFilterChange({
        categories: selectedCategories,
        styles: selectedStyles,
        colors: selectedColors,
      });
    }, [selectedCategories, selectedStyles, selectedColors, onFilterChange]);


  useEffect(() => {
    // Fetch filters from the backend
    const fetchFilters = async () => {
      try {
        const response = await axios.get(API_ENDPOINTS.getFilters);
        const data = response.data;

        if (data) {
          setCategories(data.types || []);
          setStyles(data.styles || []);
          setColors(data.colors || []);
        }
      } catch (error) {
        console.error('Error fetching filters:', error);
      }
    };
    fetchFilters();
  }, []);

  useEffect(() => {
    // Filter styles based on the selected categories' `proty_id`
    if (selectedCategories.length > 0) {
      const selectedCategoryIds = selectedCategories.map(category => category.proty_id);
      const filtered = styles.filter(style => selectedCategoryIds.includes(style.proty_id));
      setFilteredStyles(filtered);
    } else {
      setFilteredStyles(styles); // If no category is selected, show all styles
    }
  }, [selectedCategories, styles]);
 
  const handleClick = (index) => {
    setSelectedIndex(index); // Update selected option
    onSortChange(index);     // Notify parent component
  };

  // Toggle the filter panel
  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  // Toggle individual sections
  const toggleCategory = () => setIsCategoryOpen(!isCategoryOpen);
  const toggleStyle = () => setIsStyleOpen(!isStyleOpen);
  const toggleColor = () => setIsColorOpen(!isColorOpen);

  // Handle selecting categories
  const handleCategoryChange = (category) => {
    setSelectedCategories((prev) =>
      prev.includes(category) ? prev.filter((c) => c !== category) : [...prev, category]
    );
  };

  // Handle selecting styles
  const handleStyleChange = (style) => {
    setSelectedStyles((prev) => {
      const updatedStyles = prev.some((s) => s.prosty_id === style.prosty_id)
        ? prev.filter((s) => s.prosty_id !== style.prosty_id)
        : [...prev, style];
      console.log('Updated Selected Styles:', updatedStyles); // Debugging
      localStorage.setItem('selectedStyles', JSON.stringify(updatedStyles));
      return updatedStyles;
    });
  };

  // Handle selecting colors (allow multiple selections)
  const handleColorChange = (color) => {
    setSelectedColors((prev) =>
      prev.some((c) => c.procol_id === color.procol_id)
        ? prev.filter((c) => c.procol_id !== color.procol_id)
        : [...prev, color]
    );
  };
  

  // Clear all filters
  const clearAllFilters = () => {
    setSelectedCategories([]);
    setSelectedStyles([]);
    setSelectedColors([]);
  };

  const getTextColor = (hexColor) => {
    // Convert hex to RGB
    const rgb = parseInt(hexColor.slice(1), 16); // Remove the "#" and parse
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;

    // Calculate brightness (luminance)
    const brightness = 0.299 * r + 0.587 * g + 0.114 * b;

    // Return white text for dark background, black text for light background
    return brightness > 186 ? '#000000' : '#FFFFFF';
  };

  return (
    <div className='drop-container'>
      <a onClick={toggleFilter}>ตัวกรอง <FaFilter /></a>

      <div className="dropdown">
        <p>เรียงจาก</p>
        <h1 className={`${selectedIndex}`}>
          {options[selectedIndex]}
        </h1>
        <ul>
          {options.map((option, index) => (
            <li
              key={index}
              className={selectedIndex === index ? 'selected' : ''}
              onClick={() => handleClick(index)}
            >
              {option}
            </li>
          ))}
        </ul>
      </div>

      {/* Show selected filters below filter button */}
      <div className="selected-filters">
        {selectedCategories.length > 0 && selectedCategories.map((category, index) => (
          <span key={index} className="filter-tag">
            {category.proty_name} <span onClick={() => handleCategoryChange(category)}>&nbsp;x</span>
          </span>
        ))}
        {selectedStyles.length > 0 && selectedStyles.map((style, index) => (
          <span key={index} className="filter-tag">
            {style.prosty_name} <span onClick={() => handleStyleChange(style)}>&nbsp;x</span>
          </span>
        ))}
        {selectedColors.length > 0 && selectedColors.map((color, index) => (
          <span
            key={index}
            className="filter-tag color-tag"
            style={{
              backgroundColor: color.procol_hexcode,
              color: getTextColor(color.procol_hexcode)
            }}
          >
            {color.procol_name}
            <span onClick={() => handleColorChange(color)}>&nbsp;x</span>
          </span>
        ))}
        {(selectedCategories.length > 0 || selectedStyles.length > 0 || selectedColors.length > 0) && (
          <button className="clear-filters" onClick={clearAllFilters}>
            ล้างทั้งหมด
          </button>
        )}
      </div>

      {/* Overlay for blur effect */}
      <div className={`overlay ${isFilterOpen ? 'show' : ''}`} onClick={toggleFilter}></div>

      {/* Filter Options Panel */}
      <div className={`filter-options ${isFilterOpen ? 'open' : ''}`}>

        {/* หมวดหมู่ (Categories) */}
        <div className="filter-header" onClick={toggleCategory}>
          หมวดหมู่
          <FaCaretDown className={`caret ${isCategoryOpen ? 'open' : ''}`} />
        </div>
        <div className={`filter-content ${isCategoryOpen ? 'open' : ''}`}>
          {categories.map((category, index) => (
            <div className="filter-option" key={index}>
              <label>
                <input
                  type="checkbox"
                  value={category.proty_name} // Display the correct category name
                  checked={selectedCategories.includes(category)}
                  onChange={() => handleCategoryChange(category)}
                />
                {category.proty_name} {/* Render the correct category name */}
              </label>
            </div>
          ))}
        </div>

        {/* สไตล์ (Styles) */}
        <div className="filter-header" onClick={toggleStyle}>
          สไตล์
          <FaCaretDown className={`caret ${isStyleOpen ? 'open' : ''}`} />
        </div>
        <div className={`filter-content ${isStyleOpen ? 'open' : ''}`}>
        {styles.map((style, index) => {
          const isChecked = selectedStyles.some((s) => s.prosty_id == style.prosty_id); // Use loose equality
          return (
            <div className="filter-option" key={index}>
              <label>
                <input
                  type="checkbox"
                  value={style.prosty_name}
                  checked={isChecked}
                  onChange={() => handleStyleChange(style)}
                />
                {style.prosty_name || "Unnamed Style"} {/* Ensure display text is rendered */}
              </label>
            </div>
          );
        })}
        </div>

        {/* สี (Colors) */}
        <div className="filter-header" onClick={toggleColor}>
          สี
          <FaCaretDown className={`caret ${isColorOpen ? 'open' : ''}`} />
        </div>
        <div className={`filter-content color-options ${isColorOpen ? 'open' : ''}`}>
          {colors.map((color, index) => (
            <span
              key={index}
              className={`color-dot ${selectedColors.some((c) => c.procol_id === color.procol_id) ? 'selected' : ''}`}
              style={{ backgroundColor: color.procol_hexcode }} // Use the color hexcode
              onClick={() => handleColorChange(color)}
            ></span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
