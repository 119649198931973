import React, { useEffect, useRef, useState } from 'react';
import { GiFlatPlatform } from 'react-icons/gi';
import { SiShopee } from 'react-icons/si';
import { FaFacebook, FaLine } from 'react-icons/fa';
import Lazadajsx from './Lazadajsx';
import './BuyButton.scss';
import { Link } from 'react-router-dom';

const BuyButton = ({ variation }) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);

  const togglePlatforms = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="buy-button-container">
      {/* Main Buy Button */}
      <Link
        to={variation?.provariation_urlshopee || '#'}
        className={`shopee-button ${variation?.provariation_urlshopee ? '' : 'disabled'}`}
      >
        <div>
          <SiShopee />
          ซื้อเลย Shopee
        </div>
      </Link>

      {/* More Platforms Button */}
      <div ref={containerRef}>
        <div
          className={`more-platforms-container ${isOpen ? 'open' : ''}`}
          onMouseDown={togglePlatforms}
        >
          <GiFlatPlatform className="more-platforms-icon" />
          <span className="more-text">เลือกแพลตฟอร์มอื่น</span>
          {/* Platform Icons */}
          <ul className="menu">
            <li>
              <div className="tooltip-menu">
                <Link
                  to={variation?.provariation_urllazada || '#'}
                  className={`platform-icon ${variation?.provariation_urllazada ? '' : 'disabled'}`}
                >
                  <Lazadajsx />
                </Link>
                <span className="tooltiptext-menu">Lazada</span>
              </div>
            </li>
            <li>
              <div className="tooltip-menu">
                <Link
                  to={variation?.provariation_urlfacebook || '#'}
                  className={`platform-icon ${
                    variation?.provariation_urlfacebook ? '' : 'disabled'
                  }`}
                >
                  <FaFacebook />
                </Link>
                <span className="tooltiptext-menu">Facebook</span>
              </div>
            </li>
            <li>
              <div className="tooltip-menu">
                <Link
                  to={variation?.provariation_urlline || '#'}
                  className={`platform-icon ${variation?.provariation_urlline ? '' : 'disabled'}`}
                >
                  <FaLine />
                </Link>
                <span className="tooltiptext-menu">Line</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BuyButton;
