import React, { useRef, useState, useEffect } from 'react';
import './../css/HomePage.scss';
import Card from '../cards/Card';
import ProductMap from '../presets/ProductMap';
import axios from 'axios';
import { API_ENDPOINTS } from '../backended/connectapi';
import FilterLink from '../presets/FilterLink';

const HomePage = () => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const sliderRef = useRef(null); // Reference to the product slider

  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);

  const [products, setProducts] = useState([]);
  const [productssale, setProductssale] = useState([]);

  const [isAutoSlidePaused, setIsAutoSlidePaused] = useState(false);
  const autoSlideDelay = 2000; // 5 seconds delay after user interaction
  const [autoSlideIntervalId, setAutoSlideIntervalId] = useState(null);

  useEffect(() => {
    // Fetch products data from API
    const fetchProducts = async () => {
      try {
        const response = await axios.get(API_ENDPOINTS.getshowproducts);
        const data = response.data;

        console.log('Fetched data:', data);

        if (Array.isArray(data)) {
          // Set products state
          setProducts(data);

          // Filter products on sale
          const saleProducts = data.filter(
            (product) =>
              product.provariation_saleprice && product.provariation_saleprice > 0
          );
          setProductssale(saleProducts);
        } else {
          console.error('API did not return an array:', data);
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    // Simulate image loading
    const timer = setTimeout(() => {
      setImagesLoaded(true); // Set images as loaded after 2 seconds
    }, 0); // Simulate a 2-second delay

    return () => clearTimeout(timer);
  }, []);

  const updateButtonVisibility = () => {
    const slider = sliderRef.current;

    // Check if we are at the start of the slider
    if (slider.scrollLeft <= 0) {
      setShowLeftButton(false);
    } else {
      setShowLeftButton(true);
    }

    // Check if we are at the end of the slider
    if (slider.scrollLeft + slider.clientWidth >= slider.scrollWidth) {
      setShowRightButton(false);
    } else {
      setShowRightButton(true);
    }
  };

  // Scroll functionality for the left and right buttons
  const scroll = (direction) => {
    const slider = sliderRef.current;
    const scrollAmount = direction === 'left' ? -300 : 300;
    const maxScrollLeft = slider.scrollWidth - slider.clientWidth;
  
    if (direction === 'right' && slider.scrollLeft >= maxScrollLeft) {
      // If we're at the end, scroll back to the beginning
      slider.scrollLeft = 0;
    } else {
      slider.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  // Handle dragging for scroll
  const handleDrag = (e) => {
    const slider = sliderRef.current;
    let startX = e.pageX - slider.offsetLeft;
    let scrollLeft = slider.scrollLeft;
    pauseAutoSlide();

    const onMouseMove = (ev) => {
      const x = ev.pageX - slider.offsetLeft;
      const walk = (x - startX) * 2;
      slider.scrollLeft = scrollLeft - walk;
    };

    const onMouseUp = () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
      updateButtonVisibility(); // Update button visibility after drag
    };

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  };
  useEffect(() => {
    updateButtonVisibility(); // Update button visibility on initial render
  }, []);

  // Pause the auto-slide and restart after a delay
  const pauseAutoSlide = () => {
    setIsAutoSlidePaused(true);
    setTimeout(() => setIsAutoSlidePaused(false), autoSlideDelay);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isAutoSlidePaused) {
        scroll('right');
      }
    }, 3000); // 3 seconds

    setAutoSlideIntervalId(intervalId);

    return () => clearInterval(intervalId);
  }, [isAutoSlidePaused]);

  const handleInteractionStart = () => {
    setIsAutoSlidePaused(true);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setIsAutoSlidePaused(false);
    }, 3000); // 3 seconds
  };

  return (
    <div className="homepage">
      <section className="hero-section">
        <img src="testbackground.png" alt="Background" className="hero-image" />
        <FilterLink
          to='/productslist'
          clearFilters={true}
          style={{ color: 'inherit', textDecoration: 'inherit' }}
        >
          <h3 target="_blank" rel="noopener noreferrer" className="order-button">
            ดูสินค้าทั้งหมด
          </h3>
        </FilterLink>
      </section>

      {/* Section 2: Grid Layout for Images */}
      <section className="product-section" onMouseLeave={handleMouseLeave}>
        <h2 className="section-title">สินค้าแนะนำ</h2>
        <div className="slider-container">
          {/* Conditionally render left button */}
          {showLeftButton && (
            <button 
            className="slider-button left" 
            onClick={() => scroll('left')}
             onMouseDown={handleInteractionStart}
             
             >
              ←
            </button>
          )}
          <div className="product-slider" ref={sliderRef} onMouseDown={handleDrag} onMouseLeave={handleMouseLeave}>
            {products.map((product, index) => (
              <div key={index} className="product-card">
                <Card
                  id={product.pro_ID}
                  title={product.pro_name}
                  colors={product.colors}
                  price={product.provariation_saleprice && product.provariation_saleprice > 0 ? product.provariation_saleprice : product.provariation_price}
                  images={product.images}
                  variations={product.variations} // Pass the variations array here
                  isLoading={!imagesLoaded}
                />
              </div>
            ))}

          </div>
          {/* Conditionally render right button */}
          {showRightButton && (
            <button className="slider-button right" onClick={() => scroll('right')} onMouseDown={handleInteractionStart}>
              →
            </button>
          )}
        </div>
      </section>
      <section className='productmap'>
        <ProductMap />
      </section>
    </div>
  );
};

export default HomePage;
