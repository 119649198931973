import React, { useState, useEffect } from 'react';
import './ProductMap.scss';
import Card from '../cards/Card';
import axios from 'axios';
import { API_ENDPOINTS } from '../backended/connectapi';

const ProductMap = () => {
  const [products, setProducts] = useState([
    {
      id: 'f491aae1-a2',
      x: '50%',
      y: '40%',
    },
    {
      id: 'f89502e7-29',
      x: '30%',
      y: '60%',
    },
    {
      id: '81cb9698-ef',
      x: '32%',
      y: '35%',
    },
    {
      id: 'e41c2204-1c',
      x: '45%',
      y: '85%',
    },
  ]);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [hoveredProduct, setHoveredProduct] = useState(null);
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await axios.get(API_ENDPOINTS.getshowproducts);
        const data = response.data;
        const filteredData = data.filter((product) =>
          products.some((p) => p.id === product.pro_ID)
        );
        setProductData(filteredData);
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };
    fetchProductData();
  }, [products]);

  const getProductDetails = (id) => {
    return productData.find((product) => product.pro_ID === id);
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  return (
    <div className='map-container'>
      <h1>เลือกตามสไตล์</h1>
      <div className="product-map-container">
        <div className="image-container">
          <img src="8c4e6bd3-8ffc-44f7-9903-5b2bd76ae8ce.png" alt="Model" className="model-image" />
          {products.map((product) => (
            <button
              key={product.id}
              className="product-button"
              style={{ left: product.x, top: product.y }}
              onMouseEnter={() => setHoveredProduct(getProductDetails(product.id))}
              onMouseLeave={() => setHoveredProduct(null)}
              onClick={() => handleProductClick(getProductDetails(product.id))}
            ></button>
          ))}
        </div>
        <div className="product-card-container">
          {selectedProduct && (
            <Card
              id={selectedProduct.pro_ID}
              title={selectedProduct.pro_name}
              colors={selectedProduct.colors}
              price={
                selectedProduct.provariation_saleprice && selectedProduct.provariation_saleprice > 0
                  ? selectedProduct.provariation_saleprice
                  : selectedProduct.provariation_price
              }
              images={selectedProduct.images}
              variations={selectedProduct.variations}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductMap;