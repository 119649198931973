import React, { useRef, useState, useEffect } from 'react';
import Card from '../cards/Card';
import './SlideshowPage.scss';
import axios from 'axios';
import { API_ENDPOINTS } from '../backended/connectapi';

const SlideshowPage = ({ products }) => {
  const containerRef = useRef(null);
  const isDown = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);
  const isDragging = useRef(false);
  const dragThreshold = 5; // Minimum movement in pixels to consider as drag
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await axios.get(API_ENDPOINTS.getshowproducts);
        const data = response.data;
        const filteredData = data.filter((product) => products.some((id) => id.pro_id === product.pro_ID));
        setProductData(filteredData);
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };
    //const filteredData = data.filter((product) => products.some((id) => id.pro_id === product.pro_ID));
    fetchProductData();
  }, [products]);
  // Function to scroll left or right
  const handleScroll = (direction) => {
    const container = containerRef.current;
    const cardWidth = container.querySelector('.card-container').offsetWidth;
    const scrollAmount = cardWidth + 16; // Adjust scroll amount to the card width plus margin
    container.scrollBy({ left: direction === 'left' ? -scrollAmount : scrollAmount, behavior: 'smooth' });
  };

  // Mouse event handlers
  const handleMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    isDown.current = true;
    isDragging.current = false; // Reset dragging state
    startX.current = e.pageX - containerRef.current.offsetLeft;
    scrollLeft.current = containerRef.current.scrollLeft;
  };

  const handleMouseLeave = () => {
    isDown.current = false;
  };

  const handleMouseUp = (e) => {
    e.preventDefault();
    e.stopPropagation();
    isDown.current = false;

    // Delay resetting isDragging to allow onClickCapture to detect dragging
    setTimeout(() => {
      isDragging.current = false;
    }, 0);
  };

  const handleMouseMove = (e) => {
    if (!isDown.current) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = x - startX.current;

    // Check if movement exceeds threshold to consider as dragging
    if (Math.abs(walk) > dragThreshold) {
      isDragging.current = true;
    }
    containerRef.current.scrollLeft = scrollLeft.current - walk;
  };

  // Touch event handlers
  const handleTouchStart = (e) => {
    isDown.current = true;
    isDragging.current = false; // Reset dragging state
    startX.current = e.touches[0].pageX - containerRef.current.offsetLeft;
    scrollLeft.current = containerRef.current.scrollLeft;
  };

  const handleTouchEnd = () => {
    isDown.current = false;

    // Delay resetting isDragging to allow onClickCapture to detect dragging
    setTimeout(() => {
      isDragging.current = false;
    }, 0);
  };

  const handleTouchMove = (e) => {
    if (!isDown.current) return;
    const x = e.touches[0].pageX - containerRef.current.offsetLeft;
    const walk = x - startX.current;

    // Check if movement exceeds threshold to consider as dragging
    if (Math.abs(walk) > dragThreshold) {
      isDragging.current = true;
    }
    containerRef.current.scrollLeft = scrollLeft.current - walk;
  };

  // Click capture handler to prevent click events during dragging
  const handleClickCapture = (e) => {
    if (isDragging.current) {
      e.preventDefault();
      e.stopPropagation();
      // Optionally reset isDragging here if needed
    }
  };

  return (
    <div className="slideshow-container">
      <button className="scroll-button left" onClick={() => handleScroll('left')}>
        {'<'}
      </button>

      <div
        className="product-slider"
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        onClickCapture={handleClickCapture} // Add this line
      >
        {productData.map((product, index) => (
          <div key={index} className="product-card">
            <Card
              id={product.pro_ID}
              title={product.pro_name}
              colors={product.colors}
              price={product.provariation_saleprice && product.provariation_saleprice > 0 ? product.provariation_saleprice : product.provariation_price}
              images={product.images}
              variations={product.variations} // Pass the variations array here
            />
          </div>
        ))}
      </div>

      <button className="scroll-button right" onClick={() => handleScroll('right')}>
        {'>'}
      </button>
    </div>
  );
};

export default SlideshowPage;
