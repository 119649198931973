import React from 'react';
import './Footer.scss'; // You'll need to create this file for styling
import { Link, useLocation } from 'react-router-dom'; // Import useLocation

const Footer = () => {
  const location = useLocation(); // Get the current location
  const hiddenRoutes = ['/LoginPage', '/dashboard'];
  if (hiddenRoutes.includes(location.pathname)) {
    return null;
  }
  return (
    <footer className="footer">
      <div className="footer-top">
        <a href="#top" className="back-to-top">Back to top ↑</a>
      </div>

      <div className="footer-columns">
        <div className="footer-column">
          <h3>About us</h3>
          <p>
            While maintaining the outstanding feature and qualities of active sportswear, our designs are adequate for comfortable and casual moments in your everyday walks and strolls.
          </p>
          <div className="contact-info">
            <p>+12345678</p>
            <p>hi@yourwebsite.com</p>
          </div>
        </div>

        <div className="footer-column">
          <h3>Shop</h3>
          <ul>
            <li><a>On sale</a></li>
            <li><a>New arrivals</a></li>
            <li><a>Bestsellers</a></li>
            <li><a>Signature</a></li>
          </ul>
        </div>

        <div className="footer-column">
          <h3>Customer Service</h3>
          <ul>
            <li><a>Shipping policy</a></li>
            <li><a>Privacy policy</a></li>
            <li><a>Terms of service</a></li>
            <li><a>Return policy</a></li>
          </ul>
        </div>

        <div className="footer-column">
          <h3>Stay in touch</h3>
          <p>Subscribe to get special offers, free giveaways, and once-in-a-lifetime deals.</p>
          <form className="subscribe-form">
            <input type="email" placeholder="Enter your email" />
            <button type="submit">Subscribe</button>
          </form>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="social-links">
          <a>Facebook</a>
          <a>Instagram</a>
          <a>Twitter</a>
        </div>
        <p>© Your Brand Name • Powered by Your Platform</p>
      </div>
    </footer>
  );
};

export default Footer;
