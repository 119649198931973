import React, { useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import axios from 'axios';
import './addvariationsform.css';
import { API_ENDPOINTS } from '../connectapi';

const ProductVariationForm = ({ productId, onSubmissionSuccess }) => {
  const [formData, setFormData] = useState({
    prosty_id: '',
    pro_name: '',
    provariation_urlshopee: '',
    provariation_urllazada: '',
    provariation_urltiktok: '',
    provariation_urlline: '',
    provariation_urlfacebook: '',
    provariation_price: '',
    procol_id: '',
    prosize_id: '',
    procata_id: '',
  });

  const [productType, setProductType] = useState({ name: '', id: '' });
  const [selectedStyleName, setSelectedStyleName] = useState('');
  const [sizes, setSizes] = useState([]);
  const [addingNewSize, setAddingNewSize] = useState(false);
  const [newSizeName, setNewSizeName] = useState('');
  const [colors, setColors] = useState([]);
  const [addingNewColor, setAddingNewColor] = useState(false);
  const [newColorName, setNewColorName] = useState('');
  const [newCategoryName, setNewCategoryName] = useState(''); // State for new category name
  const [addingNewCategory, setAddingNewCategory] = useState(false);
  const [categories, setCategories] = useState([]);
  const [newColorHex, setNewColorHex] = useState('#ffffff');
  const [selectedColorHex, setSelectedColorHex] = useState('');
  const [imagePreviews, setImagePreviews] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [draggedImageIndex, setDraggedImageIndex] = useState(null);

  useEffect(() => {
    console.log('productId:', productId); // Debugging: Check if productId is received
    if (productId) {
      fetchProductData(productId);
    }
    fetchColors();
    fetchCategories();
  }, [productId]);

  useEffect(() => {
    if (productType.id) {
      console.log(productType);
    }
  }, [productType]);

  const fetchProductData = async (pro_id) => {
    try {
      const productResponse = await axios.get(API_ENDPOINTS.getProductById(pro_id));
      const productData = productResponse.data;
      console.log("Product Data: ", productData); // Full product data log for reference

      // Set the product name
      setFormData(prevData => ({
        ...prevData,
        pro_name: productData.pro_name || 'Unnamed Product',
      }));

      // Access proty_name and proty_id directly
      if (productData.proty_name && productData.proty_id) {
        setProductType({
          name: productData.proty_name,
          id: productData.proty_id,
        });
        console.log({
          name: productData.proty_name,
          id: productData.proty_id,
        });
      }

      // Set style if available
      if (productData.prosty_name && productData.prosty_id) {
        setFormData(prevData => ({ ...prevData, prosty_id: productData.prosty_id }));
        setSelectedStyleName(productData.prosty_name);
      }

      // Fetch sizes based on product type ID
      const sizeResponse = await axios.get(API_ENDPOINTS.getSizesByType(productData.proty_id));
      setSizes(sizeResponse.data);
      console.log(productData.proty_id);
    } catch (error) {
      console.error('Error fetching product data:', error);
    }
  };

  const fetchColors = async () => {
    try {
      const response = await axios.get(API_ENDPOINTS.getColors);
      setColors(response.data);
    } catch (error) {
      console.error('Error fetching colors:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(API_ENDPOINTS.getCategories);
      console.log("Fetched categories:", response.data); // Inspect the data
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleNewSizeSubmit = async () => {
    if (newSizeName.trim()) {
      try {
        console.log("Product Type:", productType.id);  // Ensure the ID is logged correctly

        // Form-urlencoded data
        const formData = new URLSearchParams();
        formData.append('action', 'add_size');
        formData.append('prosize_name', newSizeName);
        formData.append('proty_id', productType.id);

        const response = await axios.post(API_ENDPOINTS.addSize, formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });

        if (response.data.prosize_id) {
          setSizes(prevSizes => [...prevSizes, response.data]);
          setFormData(prevData => ({ ...prevData, prosize_id: response.data.prosize_id }));
          setAddingNewSize(false);
          setNewSizeName('');
        } else {
          alert('Error adding new size');
        }
        console.log(response.data);
      } catch (error) {
        console.error('Error adding new size:', error);
        alert('Error adding new size');
      }
    }
  };


  const handleNewSizeCancel = () => {
    setAddingNewSize(false);
    setNewSizeName('');
  };

  const handleNewCategorySubmit = async () => {
    if (newCategoryName.trim()) {
      try {
        console.log("Submitting new category:", newCategoryName);

        // Prepare form data
        const formData = new URLSearchParams();
        formData.append('action', 'add_category');
        formData.append('procata_name', newCategoryName);

        // Sending the POST request with form-urlencoded data
        const response = await axios.post(API_ENDPOINTS.addCategory, formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });

        console.log("Server response:", response.data);

        setCategories(prevCategories => [...prevCategories, response.data]);
        setFormData(prevData => ({ ...prevData, procata_id: response.data.procata_id }));
        setAddingNewCategory(false);
        setNewCategoryName('');
      } catch (error) {
        console.error('Error adding new category:', error);
        alert('Error adding new category');
      }
    }
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const newPreviews = files.map(file => URL.createObjectURL(file));

    setImagePreviews(prevPreviews => {
      const updatedPreviews = [...prevPreviews, ...newPreviews].slice(0, 4);
      return updatedPreviews;
    });

    setImageFiles(prevFiles => {
      const updatedFiles = [...prevFiles, ...files].slice(0, 4);
      return updatedFiles;
    });

    if (!selectedImage && newPreviews.length > 0) {
      setSelectedImage(newPreviews[0]);
    }
  };

  const handleImageClick = (index) => {
    setSelectedImage(imagePreviews[index]);
  };

  const handleDragStart = (index) => {
    setDraggedImageIndex(index);
  };

  const handleDragOver = (index) => {
    if (draggedImageIndex === null || draggedImageIndex === index) return;

    const updatedPreviews = [...imagePreviews];
    const updatedFiles = [...imageFiles];

    const [draggedPreview] = updatedPreviews.splice(draggedImageIndex, 1);
    const [draggedFile] = updatedFiles.splice(draggedImageIndex, 1);

    updatedPreviews.splice(index, 0, draggedPreview);
    updatedFiles.splice(index, 0, draggedFile);

    setDraggedImageIndex(index);
    setImagePreviews(updatedPreviews);
    setImageFiles(updatedFiles);
  };

  const handleDragEnd = () => {
    setDraggedImageIndex(null);
  };

  const handleImageRemove = (index) => {
    const updatedPreviews = imagePreviews.filter((_, i) => i !== index);
    const updatedFiles = imageFiles.filter((_, i) => i !== index);

    setImagePreviews(updatedPreviews);
    setImageFiles(updatedFiles);

    if (selectedImage === imagePreviews[index]) {
      setSelectedImage(updatedPreviews.length > 0 ? updatedPreviews[0] : null);
    }

    URL.revokeObjectURL(imagePreviews[index]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "procol_id") {
      const selectedColor = colors.find(color => color.procol_id === value);
      if (selectedColor) {
        setFormData(prevData => ({
          ...prevData,
          procol_id: selectedColor.procol_id,
          procol_hexcode: selectedColor.procol_hexcode // Assuming you need this too
        }));
        setSelectedColorHex(selectedColor.procol_hexcode);
      }
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleNewColorSubmit = async () => {
    if (newColorName.trim() && newColorHex) {
      try {
        // Prepare form data
        const formData = new URLSearchParams();
        formData.append('action', 'add_color');
        formData.append('procol_name', newColorName);
        formData.append('procol_hexcode', newColorHex);

        // Send form-urlencoded request
        const response = await axios.post(API_ENDPOINTS.addColor, formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });

        const newColor = response.data;
        setColors(prevColors => [...prevColors, newColor]); // Add new color to existing list

        // Set the form to use the new color's ID and update the selected color hex code
        setFormData(prevData => ({
          ...prevData,
          procol_id: newColor.procol_id,
        }));
        setSelectedColorHex(newColor.procol_hexcode);

        // Reset the form
        setAddingNewColor(false);
        setNewColorName('');
        setNewColorHex('#ffffff');
        console.log(newColor);
      } catch (error) {
        console.error('Error adding new color:', error);
        alert('Error adding new color');
      }
    }
  };


  const handleNewColorCancel = () => {
    setAddingNewColor(false);
    setNewColorName('');
    setNewColorHex('#ffffff');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log('Form Data:', formData); // Debugging: Check if values are correct

      const variationData = new FormData();
      variationData.append('action', 'create_variation');
      variationData.append('pro_id', productId); // Make sure 'productId' is set correctly
      variationData.append('prosize_id', formData.prosize_id);
      variationData.append('procata_id', formData.procata_id);
      variationData.append('procol_id', formData.procol_id);
      variationData.append('provariation_price', formData.provariation_price);

      variationData.append('provariation_urlshopee', formData.provariation_urlshopee || '');
      variationData.append('provariation_urllazada', formData.provariation_urllazada || '');
      variationData.append('provariation_urltiktok', formData.provariation_urltiktok || '');
      variationData.append('provariation_urlline', formData.provariation_urlline || '');
      variationData.append('provariation_urlfacebook', formData.provariation_urlfacebook || '');

      console.log('Image Files:', imageFiles);

      const variationResponse = await axios.post(API_ENDPOINTS.createProductVariation, variationData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Variation Response:', variationResponse);

      if (variationResponse.status === 200) {
        const provariation_id = variationResponse.data.provariation_id;
        console.log(" test progressing. . .");
        // Upload images with the correct order
        const imageUploadPromises = imageFiles.map((image, index) => {

          const uploadFormData = new FormData();
          uploadFormData.append('action', 'upload_image');  // Ensure this action is correctly set
          uploadFormData.append('images', image);  // Append the actual image file
          uploadFormData.append('provariation_id', provariation_id);  // Pass the variation ID
          uploadFormData.append('order', index + 1);  // Set the image order
        
          return axios.post(API_ENDPOINTS.uploadImage, uploadFormData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        });        

        await Promise.all(imageUploadPromises);

        alert('Variation and images added successfully!');
        onSubmissionSuccess();
      } else {
        alert('Error creating variation');
      }

    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      alert('An error occurred during submission. Please check the console for more details.');
    }
  };

  return (
    <div className="add-product-form">
      {formData.pro_name ? (
        <h2>ชื่อสินค้า: {formData.pro_name}</h2>
      ) : (
        <h2>Loading product data...</h2>
      )}
      <form onSubmit={handleSubmit} className='varform'>
        <div className="image-upload-section">
          <div className="main-image-preview">
            {selectedImage ? (
              <img src={selectedImage} alt="Selected" />
            ) : (
              <p>No image selected</p>
            )}
          </div>
          <div className="thumbnail-previews">
            {imagePreviews.map((preview, index) => (
              <div
                key={index}
                className="thumbnail-wrapper"
                onClick={() => handleImageClick(index)}
                draggable
                onDragStart={() => handleDragStart(index)}
                onDragOver={() => handleDragOver(index)}
                onDragEnd={handleDragEnd}
              >
                <img src={preview} alt={`Thumbnail ${index + 1}`} />
                <button type="button" className="remove-image" onClick={() => handleImageRemove(index)}>X</button>
              </div>
            ))}
          </div>
          {imagePreviews.length < 4 && (
            <div className="add-image-button">
              <label>
                <input
                  type="file"
                  onChange={handleImageUpload}
                  accept="image/*"
                  multiple
                />
                <span>Add Images</span>
              </label>
            </div>
          )}
        </div>
        <div className="product-details">
          <label>
            ประเภทสินค้า:
            <input type="text" value={productType.name} readOnly />
          </label>
          <label>
            ทรงของสิ้นค้า:
            <input type="text" value={selectedStyleName} readOnly />
          </label>
          <label>
            ขนาด:
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!addingNewSize ? (
                <>
                  <select
                    name="prosize_id"
                    value={formData.prosize_id}
                    onChange={(e) => {
                      if (e.target.value === 'add_new') {
                        setAddingNewSize(true);
                      } else {
                        handleChange(e);
                      }
                    }}
                    required
                  >
                    <option value="">เลือกขนาด</option>
                    {sizes && Array.isArray(sizes) && sizes.length > 0 ? (
                      sizes.map(size => (
                        <option key={size.prosize_id} value={size.prosize_id}>
                          {size.prosize_name}
                        </option>
                      ))
                    ) : (
                      <option value="">No sizes available</option>
                    )}
                    <option value="add_new">+ Add new Size</option>
                  </select>
                </>
              ) : (
                <div className="new-size-input">
                  <input
                    type="text"
                    value={newSizeName}
                    onChange={(e) => setNewSizeName(e.target.value)}
                    placeholder="Enter new size name"
                    required
                  />
                  <button type="button" onClick={handleNewSizeSubmit}>✔️</button>
                  <button type="button" onClick={handleNewSizeCancel}>❌</button>
                </div>
              )}
            </div>
          </label>
          <label>
            หมวดหมู่:
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!addingNewCategory ? (
                <>
                  <select
                    name="procata_id" // Ensure this is procata_id to correctly update formData
                    value={formData.procata_id}
                    onChange={(e) => {
                      if (e.target.value === 'add_new') {
                        setAddingNewCategory(true);
                      } else {
                        handleChange(e);  // Correctly updates procata_id in formData
                      }
                    }}
                    required
                  >
                    <option value="">เลือกหมวดหมู่</option>
                    {categories.map(category => (
                      <option key={category.procata_id} value={category.procata_id}>
                        {category.procata_name}
                      </option>
                    ))}
                    <option value="add_new">+ เพิ่มหมวดหมู่ใหม่</option>
                  </select>
                </>
              ) : (
                <div className="new-category-input">
                  <input
                    type="text"
                    value={newCategoryName}
                    onChange={(e) => setNewCategoryName(e.target.value)}
                    placeholder="Enter new category name"
                    required
                  />
                  <button type="button" onClick={handleNewCategorySubmit}>✔️</button>
                  <button type="button" onClick={() => setAddingNewCategory(false)}>❌</button>
                </div>
              )}
            </div>
          </label>
          <label>
            สีของสินค้า:
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {selectedColorHex && (
                <span
                  style={{
                    backgroundColor: selectedColorHex,
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    display: 'inline-block',
                    marginLeft: '10px',
                    border: '1px solid #000',
                  }}
                ></span>
              )}
              <select
                style={{ paddingLeft: '5%' }}
                name="procol_id"
                value={formData.procol_id}
                onChange={(e) => {
                  const selectedColorId = e.target.value;
                  console.log("Selected Color ID:", selectedColorId); // Debugging output

                  if (selectedColorId === 'add_new') {
                    setAddingNewColor(true);
                  } else {
                    // Find color with a check to ensure 'procol_id' exists and is not undefined
                    const selectedColor = colors.find(color => color.procol_id && color.procol_id.toString() === selectedColorId);

                    console.log("Found Color:", selectedColor); // Debugging output

                    if (selectedColor) {
                      setFormData(prevData => ({
                        ...prevData,
                        procol_id: selectedColor.procol_id
                      }));
                      setSelectedColorHex(selectedColor.procol_hexcode);
                    } else {
                      console.error('Selected color not found');
                    }
                  }
                }}
                key={colors.length}  // Adding key to force re-render when colors change
                required
              >
                <option value="">เลือกสี</option>
                {colors.map((color) => (
                  <option key={color.procol_id} value={color.procol_id ? color.procol_id.toString() : ''}>
                    {color.procol_name}
                  </option>
                ))}
                <option value="add_new">+ Add new Color</option>
              </select>
            </div>
          </label>
          {addingNewColor && (
            <div className="new-color-input">
              <input
                type="text"
                value={newColorName}
                onChange={(e) => setNewColorName(e.target.value)}
                placeholder="Enter new color name"
                required
              />
              <SketchPicker
                color={newColorHex}
                onChangeComplete={(color) => setNewColorHex(color.hex)}
              />
              <button type="button" onClick={handleNewColorSubmit}>✔️</button>
              <button type="button" onClick={handleNewColorCancel}>❌</button>
            </div>
          )}
          <label>
            ราคาสินค้า:
            <input
              type="number"
              name="provariation_price"
              value={formData.provariation_price}
              onChange={handleChange}
              required
            />
          </label>
          <div className="input-container">
            <label>
              URL Shopee:
              <div className="input-with-icon">
                <img className="icon" src="assets/shopeeicon.png" alt="Shopee Icon" />
                <input
                  className="input-field"
                  type="text"
                  name="provariation_urlshopee"
                  value={formData.provariation_urlshopee}
                  onChange={handleChange}
                  placeholder='ลิงค์ของ Shopee'
                />
              </div>

            </label>
          </div>
          <div className="input-container">
            <label>
              URL Lazada:
              <div className="input-with-icon">
                <img className="icon" src="assets/lazadaicon.png" alt="Lazada" />
                <input
                  className="input-field"
                  type="text"
                  name="provariation_urllazada"
                  value={formData.provariation_urllazada}
                  onChange={handleChange}
                  placeholder='ลิงค์ของ Lazada'
                />
              </div>

            </label>
          </div>
          <div className="input-container">
            <label>
              URL Tiktok:
              <div className="input-with-icon">
                <img className="icon" src="assets/Tiktok.png" alt="Tiktok" />
                <input
                  className="input-field"
                  type="text"
                  name="provariation_urltiktok"
                  value={formData.provariation_urltiktok}
                  onChange={handleChange}
                  placeholder='ลิงค์ของ Tiktok'
                />
              </div>

            </label>
          </div>
          <div className="input-container">
            <label>
              URL Line:
              <div className="input-with-icon">
                <img className="icon" src="assets/Line.png" alt="Line" />
                <input
                  className="input-field"
                  type="text"
                  name="provariation_urlline"
                  value={formData.provariation_urlline}
                  onChange={handleChange}
                  placeholder='ลิงค์ของ Line'
                />
              </div>

            </label>
          </div>
          <div className="input-container">
            <label>
              URL Facebook:
              <div className="input-with-icon">
                <img className="icon" src="assets/facebook.png" alt="Facebook" />
                <input
                  className="input-field"
                  type="text"
                  name="provariation_urlfacebook"
                  value={formData.provariation_urlfacebook}
                  onChange={handleChange}
                  placeholder='ลิงค์ของ Facebook'
                />
              </div>
            </label>
          </div>
          <button type="submit">Add Product</button>
        </div>
      </form>
    </div>
  );
};

export default ProductVariationForm;
