import React, { useState } from 'react';
import Modal from 'react-modal';
import './registerform.css';
import { API_ENDPOINTS } from '../connectapi';
import axios from 'axios';

Modal.setAppElement('#root');

const RegisterForm = () => {
  const [formData, setFormData] = useState({
    user_userid: '',
    user_password: '',
    user_repeat_password: '',
    user_name: '',
    user_middlename: '',
    user_lastname: '',
    user_email: '',
    user_phone: '',
    user_position: '',
    user_status: '',
  });
  const [isUserIdTaken, setIsUserIdTaken] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate passwords match
    if (formData.user_password !== formData.user_repeat_password) {
      alert("Passwords do not match!");
      return;
    }
  
    try {
      // Prepare data to be sent to the backend
      const data = {
        action: 'register',
        user_userid: formData.user_userid,    // Match with your backend
        user_password: formData.user_password,
        user_name: formData.user_name,
        user_middlename: formData.user_middlename,
        user_lastname: formData.user_lastname,
        user_email: formData.user_email,
        user_phone: formData.user_phone,
        user_position: formData.user_position,
        user_status: formData.user_status,
      };
  
      // Send POST request to register the user
      const response = await axios.post(API_ENDPOINTS.registerUser, data);
  
      // Check response from the server
      if (response.status === 200 && response.data.message === "User registered successfully") {
        alert('User created successfully');
        // Optionally clear the form or redirect
      } else if (response.data.message === "User ID already exists") {
        setIsUserIdTaken(true); // Set the state to show the modal
      } else {
        alert('Error creating user');
      }
    } catch (error) {
      // Catch network or server errors
      console.error('Error:', error);
      if (error.response) {
        alert('Error: ' + error.response.data.message); // Show the server error message
      } else {
        alert('Error connecting to the server.');
      }
    }
  };
  

  return (
    <div className="register-form">
      <h2>เพิ่มผู้ใช้</h2>
      <form onSubmit={handleSubmit}>
        <label>
          ไอดีผู้ใช้
          <input type="text" name="user_userid" value={formData.user_userid} onChange={handleChange} required />
        </label>
        <label>
          รหัสผ่าน:
          <input type="password" name="user_password" value={formData.user_password} onChange={handleChange} required />
        </label>
        <label>
          ยืนยันรหัสผ่าน:
          <input type="password" name="user_repeat_password" value={formData.user_repeat_password} onChange={handleChange} required />
        </label>
        <div className="name-fields">
          <label>
            ชื่อ:
            <input type="text" name="user_name" value={formData.user_name} onChange={handleChange} required />
          </label>
          <label>
            ชื่อกลาง:
            <input type="text" name="user_middlename" value={formData.user_middlename} onChange={handleChange} />
          </label>
          <label>
            นามสกุล:
            <input type="text" name="user_lastname" value={formData.user_lastname} onChange={handleChange} required />
          </label>
        </div>
        <div className="contact-fields">
          <label>
            Email:
            <input type="email" name="user_email" value={formData.user_email} onChange={handleChange} required />
          </label>
          <label>
            เบอร์ติดต่อ:
            <input type="tel" name="user_phone" value={formData.user_phone} onChange={handleChange} required />
          </label>
        </div>
        <div className="status-fields">
          <label>
            ตำแหน่ง:
            <select name="user_position" value={formData.user_position} onChange={handleChange} required>
              <option value="admin">Admin</option>
              <option value="manager">Manager</option>
            </select>
          </label>
        </div>
        <button type="submit">ยืนยัน</button>
      </form>

      <Modal
        isOpen={isUserIdTaken}
        onRequestClose={() => setIsUserIdTaken(false)}
        contentLabel="User ID Exists"
        className="Modal"
        overlayClassName="Overlay"
      >
        <h2>User ID already exists</h2>
        <p>Please choose a different User ID.</p>
        <button type="button" onClick={() => setIsUserIdTaken(false)}>Close</button>
      </Modal>
    </div>
  );
};

export default RegisterForm;
