const API_BASE_URL = 'https://doubleplaloma.com/Apiconnect';

export const API_ENDPOINTS = {
  baseurl: `${API_BASE_URL}`,

  registerUser: `${API_BASE_URL}/user_api.php`,
  getAllUsers: `${API_BASE_URL}/user_api.php`,
  getUserById: `${API_BASE_URL}/user_api.php`,
  deleteUser: `${API_BASE_URL}/user_api.php`,
  updateUser: `${API_BASE_URL}/user_api.php`,
  loginUser: `${API_BASE_URL}/user_api.php`,
  checkSession: `${API_BASE_URL}/user_api.php?action=check_session`,

  createProduct: `${API_BASE_URL}/product_api.php`,
  getProducts: `${API_BASE_URL}/product_api.php`,
  getproductsvar: `${API_BASE_URL}/product_api.php?action=get_productsvar`,
  getProductById: (id) => `${API_BASE_URL}/product_api.php?id=${id}`,
  updateProduct: `${API_BASE_URL}/product_api.php`,
  deleteProduct: (id) => `${API_BASE_URL}/product_api.php?id=${id}`,
  getProductTypes: `${API_BASE_URL}/product_api.php?action=get_types`,
  getProductStyles: (type_id) => `${API_BASE_URL}/product_api.php?action=get_styles&type_id=${type_id}`,
  getProductCollections: `${API_BASE_URL}/product_api.php?action=get_collections`,
  addStyle: `${API_BASE_URL}/product_api.php?action=add_style`,

  addSize: `${API_BASE_URL}/variation_api.php?action=add_size`,
  getSizesByType: (proty_id) => `${API_BASE_URL}/variation_api.php?action=get_sizebyid&type_id=${proty_id}`,
  getCategories: `${API_BASE_URL}/variation_api.php?action=get_categories`,
  addCategory: `${API_BASE_URL}/variation_api.php?action=add_category`,
  createProductVariation: `${API_BASE_URL}/variation_api.php`,
  uploadImage: `${API_BASE_URL}/variation_api.php?action=upload_image`,
  getColors: `${API_BASE_URL}/variation_api.php?action=get_colors`,
  addColor: `${API_BASE_URL}/variation_api.php?action=add_color`,

  getshowproducts: `${API_BASE_URL}/showproducts_api.php?action=get_products`,
  showproductbyid: (pro_ID) => `${API_BASE_URL}/showproducts_api.php?action=get_product_by_id&pro_ID=${pro_ID}`,

  getSuggestedProducts: (pro_ID) => `${API_BASE_URL}/showproducts_api.php?action=get_suggested_products&pro_ID=${pro_ID}`,
  getProductByIdsugested: `${API_BASE_URL}/showproducts_api.php?action=get_product_by_id`,

  updateProduct: `${API_BASE_URL}/product_api.php`,

  getFilters: `${API_BASE_URL}/getFilters.php`,
  getCategories:`${API_BASE_URL}/getCategories.php`,
  getStyles:`${API_BASE_URL}/getStyles.php`,

  getCategorysID: (category) => `${API_BASE_URL}/getCategoryByID.php?category=${category}`,
  getStyleID: (style) => `${API_BASE_URL}/getStyleByID.php?Style=${style}`,


};
