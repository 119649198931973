import React, { useState, useEffect } from 'react';
import './Card.scss';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_ENDPOINTS } from '../backended/connectapi';

const Card = ({ id, title, price, images, isLoading, colors = [], sale = null, saleprice = null, variations = [] }) => {
  const [selectedImage, setSelectedImage] = useState(images && images.length > 0 ? images[0] : null);
  const [colorOptions, setColorOptions] = useState([]);

  useEffect(() => {
    // Filter variations to include only those with images
    const filteredVariations = variations.filter(variation => variation.proimg_url);
    if (filteredVariations.length > 0) {
      setSelectedImage(filteredVariations[0].proimg_url);
    } else if (images && images.length > 0) {
      setSelectedImage(images[0]);
    }
  }, [images, variations]);

  const handleColorHover = (color) => {
    const variation = variations.find((v) => v.procol_id === getProcolIdByName(color.name) && v.proimg_url);
    if (variation) {
      const image = variation.proimg_url;
      setSelectedImage(image);
    }
  };

  useEffect(() => {
    axios.get(API_ENDPOINTS.getColors)
      .then(response => {
        setColorOptions(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);


  const getProcolIdByName = (name) => {
    const color = colorOptions.find((c) => c.procol_name === name);
    return color ? color.procol_id : null;
  };

  return (
    <div className={`card-container`}>
      <Link draggable='false' to={`/productsdetail/${id}/${encodeURIComponent(title)}`} style={{ textDecoration: 'none' , color:'inherit'}}>
        <div className={`card ${isLoading ? 'loading' : ''}`}>
          {isLoading ? (
            <div className="skeleton"></div>
          ) : (
            <>
              {selectedImage ? (
                <img draggable='false' src={`${API_ENDPOINTS.baseurl}/${selectedImage}`} alt={title} className="card-image" />
              ) : (
                <div className="no-image">No Image</div>
              )}
            </>
          )}
        </div>
      
        <div className="card-info">
          <div className="color-options">
            {colors.length > 4 ? (
              <>
                {colors.slice(0, 3).map((color, index) => (
                  <span
                    key={index}
                    className="color-dot"
                    style={{ backgroundColor: color.hexcode }}
                    onMouseOver={() => handleColorHover(color)}
                  ></span>
                ))}
                <span className="color-dot more-colors">
                  <span className="deep-blue" style={{ backgroundColor: '#032B44' }}>
                    +{colors.length - 3}
                  </span>
                </span>
              </>
            ) : (
              colors.map((color, index) => (
                <span
                  key={index}
                  className="color-dot"
                  style={{ backgroundColor: color.hexcode }}
                  onMouseOver={() => handleColorHover(color)}
                ></span>
              ))
            )}
          </div>
          <h3 className="card-title">{title}</h3>

          {/* Conditional rendering: If there's a sale, show the original price and sale price, else show only the original price */}
          {sale && saleprice ? (
            <div className="price-info">
              <p className="card-price">
                <strike>{price} บาท</strike> <span className="sale-tag">{sale} OFF</span>
              </p>
              <p className="sale-price">{saleprice} บาท</p>
            </div>
          ) : (
            <p className="card-price">{price} บาท</p>
          )}
        </div>
      </Link>
    </div>
  );
};

export default Card;