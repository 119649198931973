import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';

import Homepage from './pages/HomePage';
import reportWebVitals from './reportWebVitals';
import Layout from './responsive/layout';
import ProductList from './pages/ProductList';
import ProductDetail from './pages/ProductDetail';
import AboutUs from './pages/AboutUs';

import ScrollToTop from './ScrollToTop';

import LoginPage from './backended/LoginPage';
import Dashboard from './backended/Dashboard';

export default function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Suspense fallback={<div>loading</div>}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Homepage />} />
            <Route path="/productslist" element={<ProductList />} />
            <Route path="/productsdetail/:id/:title" element={<ProductDetail />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/LoginPage" element={<LoginPage />} />
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

reportWebVitals();
