// src/components/ProductDetail.jsx

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useSwipeable } from 'react-swipeable';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../css/ProductDetail.scss';
import SlideshowPage from '../presets/SlideshowPage';
import BuyButton from '../presets/BuyButton';
import axios from 'axios';
import { API_ENDPOINTS } from '../backended/connectapi';

const ProductDetail = () => {
  const { id: pro_ID } = useParams(); // Get the product ID from the URL

  const [product, setProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [suggestedProducts, setSuggestedProducts] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  // State for selected options
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);

  const [selectedVariation, setSelectedVariation] = useState(null);

  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
  });

  useEffect(() => {
    setProduct(null);
    setSelectedImage(null);
    setImages([]);
    setError(null);
    setLoading(true);
    setSelectedGender(null);
    setSelectedColor(null);
    setSelectedSize(null);
    setSelectedVariation(null);
  }, [pro_ID]);

  // Fetch product data when pro_ID changes
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(API_ENDPOINTS.showproductbyid(pro_ID));
        const data = response.data;
        console.log('Fetched product:', data);

        if (data && !data.error) {
          setProduct(data);
          setLoading(false);
        } else {
          setError('Product not found');
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching product:', error);
        setError('Error fetching product');
        setLoading(false);
      }
    };

    fetchProduct();
  }, [pro_ID]);

  // Fetch suggested products when pro_ID changes
  useEffect(() => {
    const fetchSuggestedProducts = async () => {
      try {
        const response = await axios.get(API_ENDPOINTS.getSuggestedProducts(pro_ID));
        const data = response.data;
        console.log('Fetched suggested products:', data);

        if (Array.isArray(data)) {
          // Transform the data as before
          const transformedProducts = data.map((product) => {

            return {
              pro_id: product.pro_ID,
              image: product.proimg_url
                ? `${API_ENDPOINTS.baseurl}/${product.proimg_url}`
                : 'https://placehold.co/600x400',
              colors: product.colors || [],
            };
          });

          setSuggestedProducts(transformedProducts);
          console.log(transformedProducts);
        } else {
          console.error('Error fetching suggested products:', data.error);
        }
      } catch (error) {
        console.error('Error fetching suggested products:', error);
      }
    };

    fetchSuggestedProducts();
  }, [pro_ID]); // Depend on pro_ID

  // Prepare gender options from product variations
  const genders = product
    ? [
        ...new Set(
          product.variations.map((variation) => variation.procata_name)
        ),
      ].filter(Boolean)
    : [];

  // Get all unique colors and sizes from product variations
  const allColors = product
    ? [
        ...new Set(
          product.variations.map((variation) => variation.procol_hexcode)
        ),
      ].filter(Boolean)
    : [];

  const allSizes = product
    ? [
        ...new Set(
          product.variations.map((variation) => variation.prosize_name)
        ),
      ].filter(Boolean)
    : [];

  // Determine availability of color options
  const colorOptions = allColors.map((color) => {
    const isAvailable = product.variations.some(
      (variation) =>
        variation.procol_hexcode === color &&
        (!selectedGender || variation.procata_name === selectedGender)
    );
    return { color, isAvailable };
  });

  // Determine availability of size options
  const sizeOptions = allSizes.map((size) => {
    const isAvailable = product.variations.some(
      (variation) =>
        variation.prosize_name === size &&
        (!selectedGender || variation.procata_name === selectedGender) &&
        (!selectedColor || variation.procol_hexcode === selectedColor)
    );
    return { size, isAvailable };
  });

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Allow unselecting options by clicking again
  const handleGenderSelect = (gender) => {
    setSelectedGender(selectedGender === gender ? null : gender);
  };

  const handleColorSelect = (color) => {
    setSelectedColor(selectedColor === color ? null : color);
  };

  const handleSizeSelect = (size) => {
    setSelectedSize(selectedSize === size ? null : size);
  };

  // Find matching variation when selected options change
  useEffect(() => {
    if (product) {
      let matchingVariation;

      if (selectedGender && selectedColor && selectedSize) {
        matchingVariation = product.variations.find((variation) => {
          return (
            variation.procata_name === selectedGender &&
            variation.procol_hexcode === selectedColor &&
            variation.prosize_name === selectedSize
          );
        });
      } else if (selectedGender && selectedColor) {
        matchingVariation = product.variations.find((variation) => {
          return (
            variation.procata_name === selectedGender &&
            variation.procol_hexcode === selectedColor
          );
        });
      } else if (selectedGender) {
        matchingVariation = product.variations.find((variation) => {
          return variation.procata_name === selectedGender;
        });
      } else {
        matchingVariation = product.variations[0];
      }

      setSelectedVariation(matchingVariation || null);
    }
  }, [product, selectedGender, selectedColor, selectedSize]);

  // Update images when selectedGender or selectedColor changes
  useEffect(() => {
    if (product) {
      let filteredVariations = product.variations;

      if (selectedGender) {
        filteredVariations = filteredVariations.filter(
          (variation) => variation.procata_name === selectedGender
        );
      }

      if (selectedColor) {
        filteredVariations = filteredVariations.filter(
          (variation) => variation.procol_hexcode === selectedColor
        );
      }

      // Collect images from the filtered variations
      const imagePaths = filteredVariations.reduce((acc, variation) => {
        if (variation.images && variation.images.length > 0) {
          const imgs = variation.images.map(
            (img) => `${API_ENDPOINTS.baseurl}/${img}`
          );
          acc.push(...imgs);
        }
        return acc;
      }, []);

      if (imagePaths.length > 0) {
        setImages(imagePaths);
        setSelectedImage(imagePaths[0]);
      } else {
        // Handle no images case
        setImages([]);
        setSelectedImage(null);
      }
    }
  }, [product, selectedGender, selectedColor]);

  // Auto-select the first available gender on initial load
  useEffect(() => {
    if (genders.length > 0 && selectedGender === null) {
      setSelectedGender(genders[0]);
    }
  }, [genders, selectedGender]);

  // Early returns after hooks and variable declarations
  if (loading) {
    return <div>Loading product...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!product) {
    return <div>No product found.</div>;
  }

  const { pro_name, pro_detail } = product;

  return (
    <div className="product-detail-container">
      <div className="product-detail">
        {isMobile ? (
          <div className="product-images-mobile">
            <Swiper slides-per-view="auto">
              {images.map((img, index) => (
                <SwiperSlide key={index}>
                  <img src={img} alt={`Slide ${index + 1}`} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ) : (
          <div className="product-images">
            <div className="thumbnail-images">
              {images.map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt={`Thumbnail ${index + 1}`}
                  className={`thumbnail ${
                    selectedImage === img ? 'selected' : ''
                  }`}
                  onClick={() => setSelectedImage(img)}
                />
              ))}
            </div>
            <div className="main-image">
              {selectedImage ? (
                <img src={selectedImage} alt="Main product" />
              ) : (
                <div className="no-image">No image available</div>
              )}
            </div>
          </div>
        )}

        <div className="product-info">
          <h1>{pro_name}</h1>
          <p className="price">
            {selectedVariation?.provariation_saleprice &&
            selectedVariation.provariation_saleprice > 0 ? (
              <>
                <span className="original-price">
                  {selectedVariation.provariation_price} ฿
                </span>
                <span className="sale-price">
                  {selectedVariation.provariation_saleprice} ฿
                </span>
              </>
            ) : (
              <span>{selectedVariation?.provariation_price} ฿</span>
            )}
          </p>

          <div className="options">
            {/* Gender Options */}
            <div className="gender-options">
              <h4>Gender:</h4>
              {genders.map((gender) => {
                const isAvailable = product.variations.some(
                  (variation) => variation.procata_name === gender
                );
                return (
                  <button
                    key={gender}
                    className={`gender ${
                      selectedGender === gender ? 'active' : ''
                    } ${!isAvailable ? 'disabled' : ''}`}
                    onClick={() =>
                      isAvailable && handleGenderSelect(gender)
                    }
                    disabled={!isAvailable}
                  >
                    {gender}
                  </button>
                );
              })}
            </div>

            {/* Color Options */}
            <h4>Color:</h4>
            <div className="color-dot-detailpage">
              {colorOptions.map(({ color, isAvailable }, index) => (
                <span
                  key={index}
                  className={`color-dot-detailpage ${
                    selectedColor === color ? 'active' : ''
                  } ${!isAvailable ? 'disabled' : ''}`}
                  style={{
                    backgroundColor: color,
                  }}
                  onClick={() => isAvailable && handleColorSelect(color)}
                ></span>
              ))}
            </div>

            {/* Size Options */}
            <div className="size-options">
              <h4>Size:</h4>
              <div className="sizes">
                {sizeOptions.map(({ size, isAvailable }, index) => (
                  <button
                    key={index}
                    className={`size ${
                      selectedSize === size ? 'active' : ''
                    } ${!isAvailable ? 'disabled' : ''}`}
                    onClick={() => isAvailable && handleSizeSelect(size)}
                    disabled={!isAvailable}
                  >
                    {size}
                  </button>
                ))}
              </div>
            </div>

            {/* Buy Button */}
            {selectedVariation ? (
              <BuyButton variation={selectedVariation} />
            ) : (
              <BuyButton variation={product.variations[0]} />
            )}
          </div>

          <div className="extra-info">
            <div
              className="product-description"
              dangerouslySetInnerHTML={{ __html: pro_detail }}
            ></div>
          </div>
        </div>
      </div>

      {/* Suggested Products Section */}
      <section className="suggested-products">
        <h2>แนะนำ</h2>
        <div className="suggested-products-container">
          <div className="suggested-products-slider">
            <SlideshowPage products={suggestedProducts} />
            
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProductDetail;
