import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll to top for every page except ProductList page
    if (pathname === '/productslist') {
      // Scroll to top ONLY if it's the first time navigating to the product list page
      window.scrollTo(0, 0);
    } else {
      // For other pages, always scroll to the top
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
};

export default ScrollToTop;
